<template>
  <div class="inov_box main">
    <div class="banner_box">
      <div class="swiper_box">
        <img src="@/assets/images/Community of Innovation.jpg" />
      </div>
    </div>
    <div class="container content">
      <div class="text_">
        We are the knowledge hub for innovators, our mission is to built a
        community for innovators, <br />we encourage creativity and support
        entrepreneurs. <br />Please join us, we can share knowledge and grow
        together in peace on earth.
      </div>
    </div>
    <div class="tips4 container">
      <div class="p1" @click="toDetail('1')">
        <img src="@/assets/images/a9.png" />
        <div class="name">ECI @ Meta</div>
      </div>
      <div class="p2" @click="toDetail('2')">
        <img src="@/assets/images/a9.png" />

        <div class="name">Artech @ ECI</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Inovation',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    toDetail(cat) {
      this.$router.push({
        path: '/InovationDetail',
        query: { cat },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.inov_box {
  .title_ {
    text-align: center;
  }
  .text_ {
    margin-top: 50px;
    text-align: center;
  }
  .tips4 {
    margin-top: 50px;
    display: flex;
    justify-content: space-evenly;
    .p1,
    .p2 {
      width: 377px;
      height: 212px;
      background-color: #e9edf2;
      line-height: 212px;
      font-size: 36px;
      text-align: center;
      margin-left: 20px;
      cursor: pointer;
      position: relative;
      .name {
        white-space: pre-wrap;
        position: absolute;
        font-size: 24px;
        font-weight: bold;
        width: 100%;
        top: 0;

        text-align: center;
        line-height: 212px;
      }
    }
    .p1:hover,
    .p2:hover {
      background-color: #2b8bc3;
    }
    .name:hover {
      color: #fff;
    }
  }
}
@media (max-width: 1000px) {
  .inov_box {
    .tips4 {
      margin-top: 50px;
      display: block;
      .p1,
      .p2 {
        margin: 10px auto;
      }
    }
  }
}
</style>
